import { useEffect } from 'react';

import useAuthorizedRoute from 'commons/auth/hooks/useAuthorizedRoute';
import { useMe } from 'commons/auth/hooks/useMe';
import { UserRole } from 'commons/auth/model/role';
import {
  getAdminDefaultPath,
  getManagerDefaultPath,
  getInspectorDefaultPath,
  getSellerManagerDefaultPath,
  getCollectorDefaultPath,
} from 'commons/auth/utils/route';

import { assert } from '@quotalab/utils';
import { useRouter } from 'next/router';
import { match } from 'ts-pattern';

const Index = () => {
  useAuthorizedRoute();
  const router = useRouter();
  const { data: me } = useMe();

  useEffect(() => {
    assert(me != null);
    router.replace(
      match(me)
        .with({ userRole: UserRole.마스터 }, getAdminDefaultPath)
        .with({ userRole: UserRole.매니저 }, getManagerDefaultPath)
        .with({ userRole: UserRole.셀러_관리자 }, getSellerManagerDefaultPath)
        .with({ userRole: UserRole.검수자 }, getInspectorDefaultPath)
        .with({ userRole: UserRole.수집자 }, getCollectorDefaultPath)
        .exhaustive(),
    );
  }, [me, router]);

  return <div />;
};

export default Index;
