import { useMe } from 'commons/auth/hooks/useMe';
import { type UserRole } from 'commons/auth/model/role';
import { clearAuthorization } from 'commons/auth/utils';
import { UserRoleUtils } from 'commons/auth/utils/role';

import { useLoginStatus } from './useLoginStatus';

import { iif, stringifyQueryParams } from '@quotalab/utils';
import { useRouter } from 'next/router';

function useAuthorizedRoute(requiredRole?: UserRole) {
  const router = useRouter();
  const { data: isLoggedIn } = useLoginStatus();
  const { data: me } = useMe();

  const isNotLoggedIn = isLoggedIn === false;
  const isNotSatisfyRole =
    me != null && requiredRole != null && !UserRoleUtils.contains(me.userRole, requiredRole);

  if (router.isReady && (isNotLoggedIn || isNotSatisfyRole)) {
    clearAuthorization();
    router.replace(
      `/login${stringifyQueryParams({
        redirectUrl: iif(
          window.location.pathname !== '/' && window.location.pathname !== '/login',
          window.location.pathname,
        ),
      })}`,
    );
    throw new Promise(() => {});
  }
}

export default useAuthorizedRoute;
