import { refreshAuth } from 'commons/auth/api';
import { clearAuthorization, getAuthorization, toAuthorizationHeader } from 'commons/auth/utils';
import { env } from 'shared/config';
import { type HTTPMethods } from 'shared/http';

import apiClient from './apiClient';

import { stringifyQueryParams } from '@quotalab/utils';
import { message } from 'antd';
import { type AxiosError, type AxiosResponse } from 'axios';

export function handleUnauthorizedError(error: AxiosError) {
  if (!window.location.pathname.startsWith('/login')) {
    window.location.href = `/login${stringifyQueryParams({
      redirectUrl: window.location.pathname,
    })}`;
  }
  return Promise.reject(error);
}

async function refreshTokenAndRetryAPICall(error: AxiosError) {
  const { accessToken, refreshToken } = getAuthorization();

  if (accessToken == null || refreshToken == null) {
    return handleUnauthorizedError(error);
  }

  try {
    await refreshAuth({ refreshToken });

    const { accessToken: newAccessToken } = getAuthorization();
    if (newAccessToken == null) {
      return handleUnauthorizedError(error);
    }

    return apiClient.request(error.config?.url ?? '/', {
      ...(error.config ?? {}),
      method: error.config?.method as HTTPMethods,
      headers: {
        ...(error.config?.headers ?? {}),
        Authorization: toAuthorizationHeader(newAccessToken),
      },
    });
  } catch {
    clearAuthorization();
    return handleUnauthorizedError(error);
  }
}

export async function handleHTTPError(error: AxiosError) {
  if (env('IS_SERVER_SIDE')) {
    return Promise.reject(error);
  }

  if (!error.response) {
    if (error.message !== 'canceled') {
      message.error(error.message ?? 'No response');
    }

    return Promise.reject(error);
  }

  if (error.response.data instanceof Blob) {
    error.response.data = JSON.parse(await error.response.data.text());
  }

  const { status } = error.response as AxiosResponse;

  switch (status) {
    case 401:
      return handleUnauthorizedError(error);
    case 403:
      return refreshTokenAndRetryAPICall(error);
    default:
      return Promise.reject(error);
  }
}
