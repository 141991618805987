import {
  type CreateUserRequest,
  type DeleteUserRequest,
  type LoginRequest,
  type RefreshAuthRequest,
  type TokenResponse,
  type UserInfo,
} from 'commons/auth/model';
import { clearAuthorization } from 'commons/auth/utils';
import apiClient, { authClient } from 'shared/api/apiClient';

export const createUser = (request: CreateUserRequest) => {
  return apiClient.post('/api/auth/signup', request);
};

export const deleteUser = (request: DeleteUserRequest) => {
  return apiClient.delete(`/api/users/${request.userId}`);
};

export const login = (request: LoginRequest) => {
  return authClient.post<TokenResponse>('/api/auth/signin', request);
};

export const logout = () => {
  clearAuthorization();
};

export const refreshAuth = (request: RefreshAuthRequest) => {
  return authClient.post<TokenResponse>('/api/auth/reissue', request);
};

export const getMe = () => {
  return apiClient.get<UserInfo>('/api/users/me');
};
