import {
  decodeToken,
  isTokenResponseData,
  saveAuthorization,
  toAuthorizationHeader,
} from 'commons/auth/utils';
import { handleHTTPError, handleUnauthorizedError } from 'shared/api/errorHandle';
import { env } from 'shared/config';
import { ACCESS_TOKEN_STORAGE_KEY } from 'shared/constants/storage';
import { createHTTPClient } from 'shared/http';
import { type StorageData } from 'shared/models/storage';

import { getLocalStorageItem } from '@quotalab/browser';
import { entries } from '@quotalab/utils';
import { type AxiosResponse, type AxiosRequestConfig } from 'axios';
import queryString from 'query-string';

export const apiHost = env('API_HOST');

interface RequestInterceptorOptions {
  injectAuthorization?: boolean;
}

const requestInterceptor = (
  config: AxiosRequestConfig,
  { injectAuthorization = true }: RequestInterceptorOptions = {},
) => {
  if (env('IS_CLIENT_SIDE')) {
    config.headers ??= {};

    const accessToken = getLocalStorageItem<StorageData<string>>(ACCESS_TOKEN_STORAGE_KEY);
    if (accessToken != null && injectAuthorization) {
      config.headers.Authorization = toAuthorizationHeader(decodeToken(accessToken.data));
    }
  }

  if (config.params != null) {
    config.params = Object.fromEntries(
      entries(config.params).filter(([, value]) => value != null && value !== ''),
    );
  }

  if (config.data instanceof FormData) {
    config.headers = {
      ...config.headers,
      'Content-Type': 'multipart/form-data',
    };
  } else {
    config.headers = {
      ...config.headers,
      'Content-Type': 'application/json',
    };
  }

  return Promise.resolve(config);
};

const apiClient = createHTTPClient(apiHost, {
  headers: { Accept: 'application/json' },
  responseType: 'json',
  withCredentials: false,
  interceptors: {
    request: requestInterceptor,
    response: (response) => {
      handleTokenResponse(response);
      return Promise.resolve(response);
    },
    onError: handleHTTPError,
  },
  paramsSerializer: {
    serialize: (params) => queryString.stringify(params, { arrayFormat: 'none' }),
  },
});

export const authClient = createHTTPClient(apiHost, {
  withCredentials: false,
  headers: { Authorization: undefined },
  interceptors: {
    response: (response) => {
      handleTokenResponse(response);
      return Promise.resolve(response);
    },
    onError: (error) => {
      if (!error.response) {
        return Promise.reject(error);
      }

      const { status } = error.response;
      switch (status) {
        case 401:
        case 403:
          return handleUnauthorizedError(error);
        default:
          return Promise.reject(error);
      }
    },
  },
});

const handleTokenResponse = (response: AxiosResponse) => {
  if (isTokenResponseData(response.data)) {
    saveAuthorization(response.data);
  }
};

export default apiClient;
