import { type TokenResponse } from 'commons/auth/model';
import { ACCESS_TOKEN_STORAGE_KEY, REFRESH_TOKEN_STORAGE_KEY } from 'shared/constants/storage';
import { type StorageData } from 'shared/models/storage';

import {
  getLocalStorageItem,
  removeLocalStorageItem,
  setLocalStorageItem,
} from '@quotalab/browser';
import { convert } from '@quotalab/utils';

export function isTokenResponseData(data: unknown): data is TokenResponse {
  if (typeof data !== 'object' || data === null) {
    return false;
  }

  return 'accessToken' in data && 'refreshToken' in data;
}

export function saveAuthorization(data: TokenResponse) {
  setLocalStorageItem<StorageData<string>>(ACCESS_TOKEN_STORAGE_KEY, {
    data: encodeToken(data.accessToken),
  });
  setLocalStorageItem<StorageData<string>>(REFRESH_TOKEN_STORAGE_KEY, {
    data: encodeToken(data.refreshToken),
  });
}

export function getAuthorization() {
  return {
    accessToken: convert(
      getLocalStorageItem<StorageData<string>>(ACCESS_TOKEN_STORAGE_KEY),
      ({ data }) => decodeToken(data),
    ),
    refreshToken: convert(
      getLocalStorageItem<StorageData<string>>(REFRESH_TOKEN_STORAGE_KEY),
      ({ data }) => decodeToken(data),
    ),
  };
}

export function clearAuthorization() {
  removeLocalStorageItem(ACCESS_TOKEN_STORAGE_KEY);
  removeLocalStorageItem(REFRESH_TOKEN_STORAGE_KEY);
}

export function toAuthorizationHeader(token: string) {
  return `Bearer ${token}`;
}

export function encodeToken(token: string) {
  return btoa(token);
}

export function decodeToken(token: string) {
  return atob(token);
}
