export function getAdminDefaultPath() {
  return '/master/manager';
}

export function getManagerDefaultPath() {
  return '/manager/user';
}

export function getSellerManagerDefaultPath() {
  return '/seller/management';
}

export function getInspectorDefaultPath() {
  return '/inspector/inspect';
}

export function getCollectorDefaultPath() {
  return '/collector/auto-collect-keyword';
}
