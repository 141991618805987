import { type ReactNode } from 'react';

export const enum UserRole {
  수집자 = 'SCRAPPER',
  검수자 = 'INSPECTOR',
  셀러_관리자 = 'SELLER_MANAGER',
  매니저 = 'MANAGER',
  마스터 = 'MASTER',
}

export const UserRoleGrade: Record<UserRole, number> = {
  [UserRole.수집자]: 0,
  [UserRole.검수자]: 0,
  [UserRole.셀러_관리자]: 0,
  [UserRole.매니저]: 1,
  [UserRole.마스터]: 2,
};

export const UserRoleTextMap: Record<UserRole, ReactNode> = {
  [UserRole.수집자]: '수집자',
  [UserRole.검수자]: '검수자',
  [UserRole.셀러_관리자]: '셀러 담당자',
  [UserRole.매니저]: '매니저',
  [UserRole.마스터]: '마스터',
};
