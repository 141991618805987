import { getMe } from 'commons/auth/api';
import { useLoginStatus } from 'commons/auth/hooks/useLoginStatus';

import { useSuspensedQuery } from '@quotalab/react-query';

export const UserQueryKey = {
  default: () => ['user'],
  me: () => [...UserQueryKey.default(), 'me'],
};

export function useMe() {
  const { data: isLoggedIn } = useLoginStatus();
  return useSuspensedQuery(UserQueryKey.me(), () => getMe(), { enabled: isLoggedIn });
}
